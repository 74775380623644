@import '../../styles/responsive';
@import '../../styles/colors';

.title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  h1 {
    font-size: 18px;
    line-height: 26px;
    font-weight: 700;
    color: $black;
    margin: 0;
  }

  img {
    height: 24px;
    width: 24px;

    &:hover {
      cursor: pointer;
    }
  }
}

.new-post-container {
  padding: 32px 24px;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);

  .input-container {
    margin-bottom: 24px;

    > label {
      font-size: 14px;
      line-height: 20px;
    }

    > label {
      display: block;
      color: $black;
      font-weight: bold;
      margin-bottom: 4px;
    }

    select {
      margin-right: 8px;
      min-width: 125px;
      border-radius: 4px;
      border: 1px solid $linen;
      padding: 6px 24px 6px 16px;
    }
  }

  .checkbox-container {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      width: 120px;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
    }
    input {
      margin: 0;
    }
  }

  .radio-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border-radius: 4px;
    border: 1px solid $linen;
    padding: 16px 16px;
    cursor: pointer;

    label {
      min-width: 50px;
    }
    input {
      margin: 0;
      width: auto;
    }

    div {
      display: flex;
      align-items: center;
    }
    span {
      margin-right: 8px;
      font-size: 14px;
      display: block;
      line-height: 12px;
      color: $black;

      &.free {
        color: $grass;
      }
    }
  }

  .radio-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }

  .preview {
    display: grid;
    grid-template-columns: repeat(6, 100px);
    grid-column-gap: 15px;
    margin-bottom: 16px;
    margin-top: 12px;
  }

  .preview-image-container {
    width: 100px;
    height: 100px;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: $linen;
    border-radius: 8px;
    overflow: hidden;
    position: relative;

    img {
      height: 100%;
      width: auto;
    }

    button {
      position: absolute;
      background-color: $red;
      color: #fff;
      bottom: 0;
      left: 0;
      width: 100%;
      border: none;
    }
  }
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
