@import '../../styles/colors';
@import '../../styles/responsive';

.nav-category-menu {
  display: flex;
  flex-direction: column;
  height: 0;
  opacity: 0;

  &.active {
    height: auto;
    opacity: 1;
  }

  .category-container {
    > span {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      color: $granite;
      font-size: 16px;
      font-weight: 500;
      padding: 8px 3px 8px 28px;

      &.active {
        color: $black;
      }

      img {
        height: 18px;
        width: 18px;
      }
    }

    .subcategory-list {
      display: flex;
      font-size: 14px;
      align-items: center;
      padding-left: 28px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-weight: 300;
      color: $black;
      img {
        width: 25px;
        margin-right: 12px;
      }
    }
  }
}
