@import '../../styles/responsive';
@import '../../styles/colors';

.reaction-container {
  display: flex;
  margin: 24px 32px 32px;

  .reaction-wrapper {
    display: flex;
    align-items: center;
    margin-right: 16px;
    color: $granite;
    font-size: 16px;
    font-weight: 300;

    &:hover {
      cursor: pointer;
      color: $green;

      svg * {
        fill: $green;
      }
    }

    svg {
      margin-right: 4px;
      * {
        fill: $granite;
      }
    }

    span {
      margin-right: 4px;
    }
  }
}
