@import "../../styles/colors";
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@400;500&display=swap);

* {
  padding: 0;
  margin: 0;
}

body {
  background: $smoke;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.check-container {
  margin-top: 135px;
  color: $smoke;
  background: $grass;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 156px;
  width: 156px;
}

.check-container div {
  height: 120px;
  width: 100px;
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  color: $black;
  margin-top: 29px;
  font-size: 24px;
}

.success-button {
  margin-top: 30px;
  background: none;
  border: 1px solid $linen;
  border-radius: 100px;
  text-decoration: none;
  width: 128px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-button button {
  border: 1px solid transparent;
  background: $smoke;
}
