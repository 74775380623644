@import '../../styles/colors';
@import '../../styles/responsive';

.logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  text-decoration-line: none;

  img {
    height: 24px;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 24px;

    &:hover {
      cursor: pointer;
      transform: rotate(360deg) scale(1.5);
    }
  }

  span {
    font-weight: 700;
    color: $black;
    font-size: 18px;
    line-height: 18px;
    margin-left: 4px;
  }

  @include laptop() {
    img {
      height: 40px;
      width: 40px;
    }
    span {
      font-size: 24px;
      line-height: 24px;
      margin-left: 8px;
    }
  }
}
