@import '../../styles/colors';
@import '../../styles/responsive';

.ads-container {
  width: 100%;
  padding-right: 0;

  @include laptop() {
    padding-right: 16px;
  }

  h1 {
    margin: 0;
    letter-spacing: 4px;
    font-weight: 500;
  }

  img {
    width: 100%;
  }

  .ad-container {
    margin-bottom: 16px;
    box-shadow: 0px 2px 8px 0px #0000000d;
    border-radius: 4px;
    background-color: #fff;

    &.empty {
      padding: 16px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: #fff;
      background-color: $ming;
      text-align: center;
      margin-bottom: 0;
      @include laptop() {
        margin-bottom: 16px;
      }
    }

    &.mini-app {
      padding: 16px;
      display: none;

      @include laptop() {
        display: block;
      }
    }

    &.subscription {
      padding: 24px 44px;
      text-align: left;
      display: none;

      @include laptop() {
        display: block;
      }

      h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }
      p {
        color: $granite;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        margin-top: 4px;
        margin-bottom: 0;
      }

      input {
        display: block;
        margin-top: 24px;
        margin-bottom: 16px;
        border-radius: 50px;
      }
    }
  }

  .footer {
    display: none;
    @include laptop() {
      display: block;
    }

    ul {
      display: flex;
      list-style: none;

      a {
        text-decoration: none;
        color: $granite;
        font-size: 14px;
        margin-right: 10px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    p {
      color: $granite;
      font-size: 14px;
      margin-top: 8px;
    }
  }
}
