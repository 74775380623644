@import '../../styles/colors';
@import '../../styles/responsive';

.messages-container {
  height: 900px;
  width: 100%;
  .messages-list {
    max-height: 800px;
    width: 100%;
    overflow-y: scroll;
  }
}

.chatroom-container {
  background-color: $cloud;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-column-gap: 16px;
  padding: 16px;
}

.grid-container {
  background-color: #fff;
}
