@import '../../styles/colors';

.listing-left-col {
  width: 100%;
  .listing-post-button {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      svg {
        path {
          stroke: #fff;
        }
      }
    }

    svg {
      margin-right: 8px;
      path {
        stroke: $grass;
      }
    }
  }

  .listings-container {
    margin-top: 10px;
    width: 100%;
  }

  .link {
    color: $grass;
    margin-left: 8px;
    cursor: pointer;
    text-decoration: underline;
  }

  .stats {
    color: $granite;
    margin: 10px 0;
    display: block;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
  }
}
