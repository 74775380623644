@import '../../styles/colors';

.button {
  display: inline-block;
  width: auto;
  border-radius: 4px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  border: 1px solid transparent;
  cursor: pointer;
  transition-duration: 0.2s;
  padding: 10px 16px;

  &.block {
    width: 100%;
  }

  &.primary {
    background-color: $grass;
    color: #fff;
    border-color: $grass;

    &:hover {
      background-color: $green;
      border-color: $green;
    }
  }
  &.secondary {
    background-color: #fff;
    color: $black;
    border-color: $divider;

    &:hover {
      background-color: $linen;
      border-color: $linen;
    }
  }
  &.tertiary {
    background-color: #fff;
    color: $grass;
    border-color: $grass;

    &:hover {
      background-color: $grass;
      color: #fff;
      border-color: $grass;
    }
  }
  &.round {
    border-radius: 25px;
  }

  &.small {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 12px;
  }

  &.huge {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
  }
}
