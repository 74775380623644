@import '../../styles/responsive';

.splash-layout {
  .verification-code-container {
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: 1fr 1fr;

    input {
      width: 100%;
      margin: 0;
    }
    button {
      width: 100%;
    }

    @include laptop() {
      grid-template-columns: 3fr 1fr;
    }
  }

  .social-media-container {
    list-style: none;
    display: flex;
    padding: 0;

    li {
      margin-right: 15px;
    }
    img {
      height: 25px;
      width: 25px;
    }
  }
}
