@import '../../styles/responsive';

.splash-layout {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  height: 100vh;
  width: 100%;

  @include laptop() {
    flex-direction: row;

    .form-container,
    .splash-container {
      width: 50%;
      height: 100%;
    }
  }

  .splash-container {
    background-image: url('../../assets/illustration/Background.png');
    background-repeat: repeat-y;
    padding: 0;
    color: #fff;
    height: 100%;
    animation: slide 60s linear infinite;

    @keyframes slide {
      0% {
        background-position-y: 0;
      }
      100% {
        background-position-y: 1000%;
      }
    }

    h2 {
      display: none;
      font-size: 38px;
      font-weight: 500;
      margin-top: 0;
    }

    ul {
      display: none;
      li {
        text-align: left;
      }
    }

    @include laptop() {
      height: 100%;
      padding: 142px 100px;
      h2,
      ul {
        display: block;
      }
    }
  }

  .form-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 32px 16px;
    position: relative;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }

    label {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      display: block;
      margin-bottom: 5px;
    }

    .logo-container {
      display: flex;
      width: 100%;
    }

    form {
      width: 100%;
    }

    .input-container {
      margin: 25px 0;
    }

    @include laptop() {
      padding: 0;
      align-items: center;

      .logo-container {
        position: absolute;
        top: 32px;
        right: 32px;
        width: auto;
      }

      form {
        width: 100%;
        max-width: 520px;
      }
    }
  }
}
