@import '../../styles/colors';
@import '../../styles/responsive';

nav {
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  border-bottom: 1px solid $linen;
  background-color: #fff;

  .nav-container {
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @include laptop() {
    .nav-container {
      margin: auto;
      max-width: $break-desktop;
      padding: 12px 16px;
    }

    .nav-left {
      display: flex;
      align-items: center;
    }

    .links-container {
      margin-left: 16px;
      ul {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        list-style: none;
        padding: 0;

        li {
          a {
            padding: 8px 16px;
            text-decoration: none;
            color: $granite;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            position: relative;

            &:hover {
              color: $black;
            }
          }
        }
      }
    }

    .nav-right {
      button {
        margin-left: 16px;
      }
    }

    .search-container {
      margin-left: 32px;
    }
  }

  @include desktop() {
    .nav-container {
      padding: 12px 16px;
    }
  }
}
