@import '../../styles/colors';
@import '../../styles/responsive';

.sidebar-container {
  position: fixed;
  left: 0;
  top: 67px;
  background-color: #fff;
  min-height: 100vh;
  height: 100%;
  overflow-y: scroll;
  display: none;
  width: 240px;
  padding-bottom: 100px;

  @include laptop() {
    display: block;
  }

  .search-container {
    width: 100%;
  }

  .sidebar-header {
    padding: 24px;
    h2 {
      font-size: 18px;
      line-height: 26px;
      font-weight: 700;
      margin: 0 0 16px 0;
    }
  }

  .sidebar-menu {
    .menu-item {
      .menu-item-header {
        padding: 12px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        line-height: 23px;
        font-weight: 700;
        color: $granite;
        &:hover {
          cursor: pointer;
          color: $black;
        }
      }
      .menu-item-list {
        display: none;
        transition-duration: 0.4s;

        &.active {
          height: auto;
          display: block;
        }

        .submenu-item {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 8px 24px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          border-right: 3px solid transparent;

          img {
            height: 25px;
            width: 25px;
            margin-right: 8px;
          }

          &:hover {
            cursor: pointer;
            background-color: $cloud;
            border-right-color: $green;
          }

          &.active {
            background-color: $cloud;
            border-right-color: $green;
          }
        }
      }
    }
  }
}
