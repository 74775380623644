@import '../../styles/responsive';
@import '../../styles/colors';

.search-container {
  position: relative;
  width: 100%;

  input {
    padding-left: 36px;
    width: 100%;
    margin-bottom: 0;
    background-color: $smoke;
    border: 1px solid $linen;
  }

  img {
    position: absolute;
    height: 16px;
    width: 16px;
    left: 12px;
    top: 13px;
  }

  @include tablet-portrait() {
    max-width: 200px;
  }

  @include desktop() {
    width: 350px;
    max-width: 350px;
  }
}
