@import './responsive';
@import './colors';

.details-container {
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);

  .header {
    padding: 32px 32px 24px;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      h1 {
        font-size: 18px;
        line-height: 26px;
        font-weight: 700;
        color: $black;
        margin: 0;
      }

      img {
        height: 24px;
        width: 24px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .sub-title {
      color: $granite;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;

      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }
  }

  .news-content {
    padding: 0 32px 32px 32px;

    font-size: 18px;
    line-height: 28px;
    color: $black;

    img {
      width: 100%;
    }
  }
}
