$break-mobile: 375px;
$break-large-mobile: 425px;
$break-tablet-portrait: 768px;
$break-laptop: 1024px;
$break-large-laptop: 1240px;
$break-desktop: 1440px;

@mixin mobile {
  @media (min-width: #{$break-mobile}) {
    @content;
  }
}

@mixin large-mobile {
  @media (min-width: #{$break-large-mobile}) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (min-width: #{$break-tablet-portrait}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$break-laptop}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$break-desktop}) {
    @content;
  }
}
