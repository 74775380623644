.badge {
  font-size: 8px;
  line-height: 14px;
  border-radius: 12px;
  padding: 3px 6px;
  color: #fff;
  font-weight: 700;
  position: absolute;
  top: -2px;
  right: -10px;
  text-size-adjust: none;
}
