$black: #1d1d1d;
$gray: #666666;
$granite: #999999;
$divider: #d3d3d3;
$light_gray: #c4c4c4;
$linen: #ebeaeb;
$cloud: #f5f5f5;
$smoke: #fafafa;

$grass: #1aad19;
$claret: #820933;
$orange: #f18f01;
$navy: #1e3888;
$ming: #3c6e71;

$red: #eb5757;
$green: rgb(34, 146, 81);
$yellow: #f2c94c;
$cerulean: #1890ff;
