@import '../../styles/colors';
@import '../../styles/responsive';

.listing-item-container {
  padding: 16px;
  background-color: #fff;
  margin-bottom: 16px;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px #0000000d;
  width: 100%;

  .title-container {
    display: grid;
    grid-column-gap: 8px;
    margin-bottom: 10px;
    grid-auto-flow: column;

    a {
      text-decoration: none;
      color: #000;
    }

    h4 {
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      margin: 0;
      display: flex;
      align-items: center;

      &:hover {
        cursor: pointer;
        color: $grass;
        text-decoration: underline;
        text-decoration-color: $grass;
      }
    }

    span {
      text-align: right;
    }

    svg {
      height: 24px;
      width: 24px;
      margin-top: 4px;
      margin-left: 4px;
    }
  }

  .images-container {
    max-height: 138px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $smoke;
    overflow-x: hidden;
    border-radius: 4px;
    position: relative;
    pointer-events: none;

    span {
      position: absolute;
      bottom: 10px;
      right: 10px;
      font-size: 12px;
      color: $granite;
      pointer-events: none;
    }

    img {
      height: 100%;
      max-height: inherit;
      width: auto;
    }

    @include laptop() {
      max-height: 300px;
      min-height: 100px;
    }
  }

  .body {
    margin: 16px 0;
    font-size: 16px;
    font-weight: 400;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $linen;
    padding-top: 16px;
    color: $granite;

    .reaction-list {
      display: flex;
      align-items: center;

      .reaction {
        margin-left: 16px;
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
          color: #000;
          svg {
            fill: #000;
            path {
              stroke: #000;
            }
          }
        }
        svg {
          height: 16px;
          width: 16px;
          fill: $granite;

          path {
            stroke: $granite;
          }
        }

        span {
          margin: 0 4px 0 8px;
        }
      }
    }
    @include laptop() {
    }
  }

  @include laptop() {
    padding: 24px;
  }
}
