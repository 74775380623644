@import '../../styles/colors';

.hamburger-menu {
  display: none;

  &.active {
    display: block;
    position: absolute;
    left: 0;
    top: 51px;
    height: calc(100vh - 51px);
    width: 100%;
    background-color: #fff;
    padding: 16px;

    ul {
      padding: 0;
      width: 100%;
      margin-top: 6px;
      list-style: none;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        padding: 6px 0;
        line-height: 26px;
        font-weight: 700;
        cursor: pointer;

        span {
          position: relative;
        }
      }
    }
  }
}

.hamburger-container {
  .hamburger {
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    width: 24px;

    span {
      display: block;
      height: 2px;
      width: 18px;
      background: $black;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
      margin-bottom: 6px;

      &:nth-child(1) {
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      &:nth-child(2) {
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      &:nth-child(3) {
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
        margin-bottom: 0;
      }
    }

    &.active {
      span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -3px;
        left: 8px;
        width: 23px;
      }

      span:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 39px;
        width: 23px;
        left: 8px;
      }
    }
  }
}
