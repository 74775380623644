@import '../../styles/colors';
@import '../../styles/responsive';

.layout {
  padding-top: 50px;
  background-color: $smoke;

  &.light {
    background-color: #fff;
  }

  @include laptop() {
    padding-top: 67px;

    main {
      margin: auto;
      max-width: $break-desktop;
    }
  }

  main {
    width: 100%;
    padding: 16px;

    @include desktop() {
      padding: 16px;
    }
  }
}
