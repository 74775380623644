@import './colors';
@import './responsive';
@import '~react-image-gallery/styles/scss/image-gallery.scss';

* {
  font-family: 'Noto Sans SC', sans-serif;
  box-sizing: border-box;
}

body input,
body textarea {
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  line-height: 20px;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid $linen;
  margin-bottom: 8px;

  &::placeholder {
    color: #999;
    font-weight: 100;
  }
}

hr {
  margin: 25px 0;
  display: block;
  height: 1px;
  border: 0;
  background-color: $linen;
  color: $linen;
  border-top: 1px solid $linen;
  padding: 0;
}

a {
  color: $cerulean;
}

.hide-sm,
.show-lg {
  display: none;

  @include laptop() {
    display: block;
  }
}
.hide-lg,
.show-sm {
  display: block;

  @include laptop() {
    display: none;
  }
}

.page-container {
  display: flex;
  justify-content: center;

  @include laptop() {
    margin-left: 240px;
  }

  &.single-col {
    margin-left: 0;
    display: block;
  }

  .page-grid {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    grid-row-gap: 16px;

    @include laptop() {
      display: grid;
      grid-column-gap: 16px;
      grid-template-columns: 70% 30%;
    }
  }

  .page-col {
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
